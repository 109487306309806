import { 
  Component, 
  OnInit,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { RoomNoService } from '../service/room-no.service';
import { LanguageService } from '../service/language.service';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { Subscription } from 'rxjs';

import {
  getNowDateObject,
  getStreamNowDate
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { Router } from '@angular/router';



@Component({
  selector: 'app-mirror-guide',
  templateUrl: './mirror-guide.component.html',
  styleUrls: ['./mirror-guide.component.scss']
})
export class MirrorGuideComponent implements OnInit, OnDestroy, AfterViewInit {
  now_date = getStreamNowDate();
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  sub: Subscription[] = [];
  appname: string;
  focusIndex = 0;
  hotelIndex = 0;
  lang: 'jpn' | 'eng';


  sidebar: SideBar<MirroGuideSideBarTiTleJpn, MirroGuideSideBarTiTleEng>[] = [
    {
      title: 'iPhone',
      title_jpn: 'iPhone',
      title_eng: 'iPhone',
      key_code: '1',
      checked: true,
      focused: true
    },
    {
      title: 'Android',
      title_jpn: 'Android',
      title_eng: 'Android',
      key_code: '2',
      checked: false,
      focused: false
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      key_code: '92',
      checked: false,
      focused: false
    }
  ];



  constructor(
    private roomNoService: RoomNoService,
    private router: Router,
    private languageService: LanguageService,
    private el: ElementRef
  ) { }
  element: HTMLElement;


  ngAfterViewInit(): void {
    this.element = this.el.nativeElement;
  }

  ngOnInit() {
// Languageをサービスしちゃう
this.lang = this.languageService.language;

if (this.languageService.language === 'eng') {
  this.appname = 'Mirroring Guide';
  this.sidebar = this.sidebar.map(d => {
    d.title = d.title_eng;
    return d;
  });
} else {

  this.appname = 'ミラーリングガイド';
}
// 現在時刻の表示
this.sub.push(
  this.now_date.subscribe(d => {
    this.now_date_obj = getNowDateObject(d, this.languageService.language);
  })
);

  }
  ngOnDestroy() {
    this.sub.forEach(d => d.unsubscribe());
  }
  setFocusIndex(index: number) {
    if (
      ['メニューへ戻る', 'Back to menu'].includes(this.sidebar[index].title)
    ) {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.hotelIndex = index;
  }


}


type MirroGuideSideBarTiTleJpn =
  | 'iPhone'
  | 'Android';

type MirroGuideSideBarTiTleEng =
| 'iPhone'
| 'Android';
