<div class="wrapper">
    <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
    <div class="top_icon"></div>
    <div class="app_name">{{ appname }}</div>
    <div class="remote_control"></div>
    <div class="datetime">
        {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }} {{ now_date_obj.hhmm }}
    </div>
    <div class="main_content_wrapper">
        <div class="main_content">
            <!--無料貸し出し品-->

            <div *ngIf="rentalIndex == 0">
                <div class="ja">

                    <div *ngIf="lang == 'jpn'">

                        <img src="/assets/service/borrowing.png" alt="Borrowing items">
                    </div>
                    <div *ngIf="lang == 'eng'">
                        <img src="/assets/service/borrowing_en.png" alt="Borrowing items">
                    </div>
                </div>
            </div>
            <!--有料サービス-->
            <div *ngIf="rentalIndex == 1">
                <div class="ja" *ngIf="lang == 'jpn'">
                    <img src="/assets/service/paidservice.png" alt="paidservice">

                </div>
                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/service/paidservices_en.png" alt="paidservice">
                </div>
            </div>


            <!--駐車場-->
            <div *ngIf="rentalIndex == 2">
                <div class="ja" *ngIf="lang == 'jpn'">
                    <img src="/assets/service/parking.png" alt="parking">
                </div>
                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/service/parking_en.png" alt="parking">

                </div>
            </div>


            <!--チェックアウト-->
            <div *ngIf="rentalIndex == 3">
                <div class="ja " *ngIf="lang=='jpn' ">
                    <img src="/assets/service/checkout.png" alt="checkout">
                </div>
                <div class="en " *ngIf="lang=='eng' ">
                    <img src="/assets/service/checkout_en.png" alt="checkout">

                </div>
            </div>

        </div>
    </div>
</div>