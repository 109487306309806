<div id="layout"></div>

<div class="header" [style.z-index]="999">
    <div>
        <img class="wifi_img" src="/assets/top-image/wi-fi.png" alt="wifi">

        <div class="wifi_ssid">
            {{ ssid }} </div>

        <div class="wifi_key">

            {{ password }}
        </div>
        <!-- <div class="qr_code">
            <qr-code [value]="qr" [size]="100" [foreground]="'black'"></qr-code>
        </div> -->
    </div>
    <div class="logo_box">
        <img src="/assets/top-image/logo.png" class="logo">
        <div class="room_info" [ngClass]="{'room_info_eng': isEng}">
            <span>
                ROOM：
            </span>
            <div class="room_number">{{ room_no }}
            </div>
        </div>
    </div>

</div>



<div class="main" [style.z-index]="-1">
    <!--<div id="particles-js"></div>-->

    <div id="icon_area">
        <ng-container *ngFor="let item of icons; let i = index">
            <div class="icon_item_box">

                <div (click)="aClick(i)" (blur)="blur(i)" class="icon_item" (focus)="focus(i)" [ngClass]="{ focused: item.focused }">
                    <img [src]="item.icon" class="icon_img restaurant" />
                    <div class="icon_title">

                        <span *ngIf="!isEng">
                            {{ item.title_ja }}
                        </span>
                        <span *ngIf="isEng">
                            {{ item.title_eng }}
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <app-right></app-right>


    <div class="weather" [ngClass]="{'weather_eng': isEng}">

        <div class="weather_today" [hidden]="!isAm">
            <div class="weather_today_title">
                <span *ngIf="!isEng">本日のお天気</span>
                <span *ngIf="isEng">Today </span>

            </div>
            <div class="weather_today_icon">
                <span class="rainy">{{ today_rainy }}</span>
                <canvas width="90" height="90" id="today"></canvas>
            </div>

            <span class="temp">
                {{ today_temp }}
            </span>
        </div>

        <div class="weather_today" [hidden]="isAm">
            <div class="weather_today_title">
                <span *ngIf="!isEng">明日のお天気</span>

                <span *ngIf="isEng">Tomorrow </span>

            </div>
            <div class="weather_today_icon">
                <canvas width="90" height="90" id="tomorrow"></canvas>
                <span class="rainy">{{ tommorow_rainy }}</span>
            </div>

            <span class="temp">
                {{ tommorow_temp }}
            </span>

        </div>

    </div>


    <div class="clock" *ngIf="!isEng">

        <div class="date">
            <div class="year">{{ now_date_obj.year }}.</div>
            <div class="month-date">{{ now_date_obj.month }}.{{ now_date_obj.day }}</div>
            <div class="week">({{ now_date_obj.day_of_week }})</div>
        </div>
        <div class="time">
            {{ now_date_obj.hhmm }}
        </div>
    </div>
    <div class="clock" *ngIf="isEng">

        <div class="date">

            <div class="month-date">{{ now_date_obj.day }}/{{ now_date_obj.month }}/</div>
            <div class="year">{{ now_date_obj.year }}</div>
            <div class="week">{{ now_date_obj.day_of_week }}</div>
        </div>
        <div class="time">
            {{ now_date_obj.hhmm }}
        </div>
    </div>

    <!-- bebot-->

    <div class="front_tel">
        <div class="tel_svg"><img src="/assets/top-image/tel.svg"></div>
            <p *ngIf="!isEng" class="front_tel_txt">フロントへは <span class="front_tel_num">092-401-1905</span>（外線番号）または<span class="front_tel_bold">エレベーターホールの内線電話</span>をご利用ください</p>
            <p *ngIf="isEng" class="front_tel_txt_en">Please call the front desk at <span class="front_tel_num">092-401-1905 </span>(outside number) or use <span class="front_tel_bold">The extension phone in the elevator hall</span></p>
        <!-- <img src="/assets/top-image/front.png" *ngIf="!isEng">-->
        <!-- <img src="/assets/top-image/front_en.png" *ngIf="isEng" class="front_eng"> -->
    </div>
</div>







<div class="popup">
    <div class="popup_title" *ngIf="!isEng"> {{ news.Title }} </div>
    <div class="popup_title" *ngIf="isEng"> {{ news.Title_en }} </div>
    <div class="popup_hour" *ngIf="!isEng"> {{ news.Hour}} </div>
    <div class="popup_hour" *ngIf="isEng"> {{ news.Hour_en}} </div>
    <div class="popup_body" *ngIf="!isEng"> {{ news.Body}} </div>
    <div class="popup_body popup_body_en" *ngIf="isEng"> {{ news.Body_en}} </div>

    <img src="/assets/top-image/popup.jpg" alt="curry" class="popup_img">
</div>

<img src="/assets/top-image/license.png" class="license">