<div class="wrapper">
    <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
    <div class="top_icon"></div>
    <div class="app_name">{{ appname }}</div>
    <div class="remote_control"></div>
    <div class="datetime">
        {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }} {{ now_date_obj.hhmm }}
    </div>
    <div class="main_content_wrapper">
        <div class="main_content flex_content">

            <div *ngIf="rentalIndex == 0">

                <div class="flex_content">

                    <div class="ja" *ngIf="lang == 'jpn'">
                        <img src="/assets/nearby/dome.png" alt="dome">
                    </div>
                    <div class="en" *ngIf="lang == 'eng'">
                        <img src="/assets/nearby/dome_en.png" alt="dome">
                    </div>

                </div>
            </div>

            <div *ngIf="rentalIndex == 1">

                <div class="flex_content">
                    <div class="ja" *ngIf="lang == 'jpn'">
                        <img src="/assets/nearby/park.png" alt="park">
                    </div>
                    <div class="en" *ngIf="lang == 'eng'">
                        <img src="/assets/nearby/park_en.png" alt="park">
                    </div>

                </div>
            </div>

            <div *ngIf="rentalIndex == 2">

                <div class="flex_content">
                    <div class="ja" *ngIf="lang == 'jpn'">
                        <img src="/assets/nearby/trip.png" alt="tripspot">
                    </div>
                    <div class="en" *ngIf="lang == 'eng'">
                        <img src="/assets/nearby/trip_en.png" alt="tripspot">
                    </div>
                </div>
            </div>
            <div *ngIf="rentalIndex == 3">
                <div class="flex_content">
                    <div class="ja" *ngIf="lang == 'jpn'">
                        <img src="/assets/nearby/shopping.png" alt="shoppingspot">
                    </div>
                    <div class="en" *ngIf="lang == 'eng'">
                        <img src="/assets/nearby/shopping_en.png" alt="shoppingspot">
                    </div>
                </div>
            </div>


            <div *ngIf="rentalIndex == 4">
                <div class="flex_content">
                    <div class="ja" *ngIf="lang == 'jpn'">
                        <img src="/assets/nearby/gourmet.png" alt="gourmetspot">
                    </div>
                    <div class="en" *ngIf="lang == 'eng'">
                        <img src="/assets/nearby/gourmet_en.png" alt="gourmetspot">
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>