<div class="wrapper">
    <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
    <div class="top_icon"></div>
    <div class="app_name">{{ appname }}</div>
    <div class="remote_control"></div>
    <div class="datetime">
        {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }} {{ now_date_obj.hhmm }}
    </div>
    <div class="main_content_wrapper">
        <div class="main_content">
            <!-- Breakfast -->
            <div *ngIf="hotelIndex == 0">
                <div class="ja" *ngIf="lang == 'jpn'">


                    <img src="/assets/mirroring/ios.png" alt="ios_mirroring">

                </div>
                <div class="en" *ngIf="lang == 'eng'">


                    <img src="/assets/mirroring/ios_en.png" alt="ios_mirroring">

                </div>



            </div>
            <!-- Lunch-->
            <div *ngIf="hotelIndex == 1">

                <div class="ja" *ngIf="lang == 'jpn'">



                    <img src="/assets/mirroring/android.png" alt="android_mirroring">

                </div>
                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/mirroring/android_en.png" alt="android_mirroring">

                </div>







            </div>

        </div>