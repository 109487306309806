<div class="wrapper">
    <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
    <div class="top_icon"></div>
    <div class="app_name">{{ appname }}</div>
    <div class="remote_control"></div>
    <div class="datetime">
        {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }} {{ now_date_obj.hhmm }}
    </div>
    <div class="main_content_wrapper">
        <div class="main_content">
            <!-- tully's COFFEE -->
            <!-- <div *ngIf="hotelIndex == 0">

                <div class="ja" *ngIf="lang == 'jpn'">
                    <img src="/assets/breakfast/tully's.png" alt="tully's COFFEE">
                </div>
                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/breakfast/tully's_en.png" alt="tully's COFFEE">
                </div>

            </div> -->
            <!-- MASCHIKI-->
            <div *ngIf="hotelIndex == 0">
                <div class="ja" *ngIf="lang == 'jpn'">
                    <img src="/assets/breakfast/maschiki.png" alt="WEST">
                </div>
                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/breakfast/maschiki_en.png" alt="WEST">
                </div>

            </div>
            <!-- WEST-->
            <!-- <div *ngIf="hotelIndex == 1">
                <div class="ja" *ngIf="lang == 'jpn'">
                    <img src="/assets/breakfast/west.png" alt="WEST">
                </div>
                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/breakfast/west_en.png" alt="WEST">
                </div>

            </div> -->
            <!-- Rao Shanghai-->
            <!-- <div *ngIf="hotelIndex == 2">
                <div class="ja" *ngIf="lang == 'jpn'">
                    <img src="/assets/breakfast/chinese.png" alt="Rao Shanghai">
                </div>
                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/breakfast/chinese_en.png" alt="Rao Shanghai">
                </div>
            </div> -->



            <!-- SEATTLE’S BEST COFFEE-->
            <!-- <div *ngIf="hotelIndex == 3">

              <div class="ja" *ngIf="lang == 'jpn'">
                <img src="/assets/breakfast/seatles.png" alt="SEATTLE’S BEST COFFEE">
            </div>
            <div class="en" *ngIf="lang == 'eng'">
                <img src="/assets/breakfast/seatles_en.png" alt="SEATTLE’S BEST COFFEE">
            </div> -->
        </div>
    </div>
</div>