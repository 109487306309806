import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { HogeComponent } from './hoge/hoge.component';
import { RightComponent } from './right/right.component';
import { HttpClientModule } from '@angular/common/http';
import { YakkanComponent } from './yakkan/yakkan.component';
import { HeaderComponent } from './shared/component/header/header.component';
import { FooterComponent } from './shared/component/footer/footer.component';
import { SidebarComponent } from './shared/component/sidebar/sidebar.component';
import { AppliListMainComponent } from './appli-list-main/appli-list-main.component';
import { AppliSideBarComponent } from './shared/component/appli-side-bar/appli-side-bar.component';
import { AppliMainComponent } from './shared/component/appli-main/appli-main.component';
import { QRCodeModule } from 'angular2-qrcode';
import { StayInfoComponent } from './stay-info/stay-info.component';
import { HotelInfoComponent } from './hotel-info/hotel-info.component';
import { NearbyInfoComponent } from './nearby-info/nearby-info.component';
import { RemoteContorlComponent } from './remote-contorl/remote-contorl.component';
import { BreakfastComponent } from './breakfast/breakfast.component';
import { MirrorGuideComponent } from './mirror-guide/mirror-guide.component';
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HogeComponent,
    RightComponent,
    YakkanComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AppliListMainComponent,
    AppliSideBarComponent,
    AppliMainComponent,
    StayInfoComponent,
    HotelInfoComponent,
    NearbyInfoComponent,
    RemoteContorlComponent,
    BreakfastComponent,
    MirrorGuideComponent
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, QRCodeModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
