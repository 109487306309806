<div class="wrapper">
    <app-appli-side-bar [sidebars]="sidebar"></app-appli-side-bar>

    <div class="top_icon" *ngIf="lang  == 'jpn'"></div>
    <div class="top_icon eng" *ngIf="lang  == 'eng'"></div>
    <div class="app_name">{{ appname }}</div>
    <div class="datetime">
        {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }} {{ now_date_obj.hhmm }}
    </div>

    <div class="remote_control"></div>
    <!-- <div class="bottom_menu">
        <img *ngIf="lang =='jpn'" src="/assets/yakkan/scroll.png">
        <img *ngIf="lang =='eng'" src="/assets/yakkan/scroll_en.png">
    </div> -->

    <div class="main_content_wrapper">

        <div class="main_content">
            <!--コインランドリー-->

            <div class="keiyaku_wrapper">
                <div class="keiyaku_wrapper_1" *ngIf="focusIndex == 0">
                    <div class="keiyaku" *ngIf="lang=='jpn'">
                        <h1>宿泊約款</h1>
                        <h2>適用範囲</h2>
                        <ol>
                            <li>当ホテルが宿泊客との間で締結する宿泊契約およびこれに関連する契約は、この約款の定めるところによるものとし、この約款に定めのない事項については、法令又は一般に確立された慣習によるものとします。</li>
                            <li>当ホテルが法令および慣習に反しない範囲で特約に応じたときは、前項の規定にかかわらず、その特約が優先するものとします。
                            </li>
                        </ol>

                        <h2>宿泊契約の申込み</h2>
                        <ol>
                            <li>当ホテルに宿泊契約の申込みをしようとする者は、次の事項を当ホテルに申し出ていただきます。</li>
                            <ol>
                                <li>
                                    宿泊者名
                                </li>
                                <li>
                                    宿泊日および到着予定時刻
                                </li>
                                <li>
                                    宿泊者または予約者の連絡先
                                </li>
                                <li>
                                    その他当ホテルが必要と認める事項
                                </li>
                            </ol>
                            <li>宿泊客が、宿泊中に前項第 2 号の宿泊日を超えて宿泊の継続を申し入れた場合、当ホテルは、その申し出がなされた時点で新たな宿泊契約の申込みがあったものとして処理し ます。</li>
                        </ol>

                        <h2>宿泊契約の成立等</h2>
                        <ol>
                            <li>
                                宿泊契約は、当ホテルが前条の申込みを承諾したときに成立するものとします。ただし、当ホテルが承諾をしなかったことを証明したときは、この限りではありません。

                            </li>
                            <li>
                                前項の規定により宿泊契約が成立したときは、宿泊期間の基本宿泊料を限度として当ホテルが定める申込金を、当ホテルが指定する日までにお支払いいただきます。
                            </li>
                            <li>
                                申込金は、まず、宿泊客が最終的に支払うべき宿泊料金に充当し、第 6 条および第 18 条 の規定を適用する事態が生じたときは、違約金に次いで賠償金の順序で充当し、残額があれば、第 12 条の規定による料金の支払いの際に返還します。
                            </li>
                            <li>
                                第 2 項の申込金を同項の規定により当ホテルが指定した日までにお支払いいただけない場合は、宿泊契約はその効力を失うものとします。ただし、申込金の支払期日を指定する に当たり、当ホテルがその旨を宿泊客に告知した場合に限ります。
                            </li>
                        </ol>

                        <h2>申込金の支払いを要しないこととする特約</h2>
                        <ol>
                            <li>前条第 2 項の規定にかかわらず、当ホテルは、契約の成立後同項の申込金の支払いを要しないこととする特約に応じることがあります。</li>
                            <li>
                                宿泊契約の申込みを承諾するに当たり、当ホテルが前条第 2 項の申込金の支払いを求めなかった場合および当該申込金の支払期日を指定しなかった場合は、前項の特約に応じ たものとして取り扱います。
                            </li>
                        </ol>

                        <h2>宿泊契約締結の拒否</h2>
                        当ホテルは、次に掲げる場合において、宿泊契約の締結に応じないことがあります。
                        <ol>
                            <li>
                                宿泊の申込みが、この約款によらないとき。
                            </li>
                            <li>
                                満室により客室の余裕がないとき。
                            </li>
                            <li>
                                宿泊しようとする者が、宿泊に関し、法令の規定、公の秩序若しくは善良の風俗に反する行為をするおそれがあると認められるとき。
                            </li>
                            <li>
                                宿泊しようとする者が、暴力団および暴力団員等又はその関係者、その他反社会的勢力であるとき。
                            </li>
                            <li>
                                宿泊しようとする者が暴力団または暴力団員が事業活動を直接的あるいは間接的に支配する法人、その他団体であるとき。
                            </li>
                            <li>
                                宿泊しようとする者が法人で、その役員のうちに暴力団員に該当する者があるとき。
                            </li>
                            <li>
                                宿泊しようとする者が他の宿泊者に著しい迷惑を及ぼす言動をしたとき。
                            </li>
                            <li>
                                宿泊しようとする者が宿泊施設若しくは宿泊施設職員（従業員）に対し、暴力、脅迫、恐喝、威圧的な不当要求を行ない、あるいは、合理的範囲を超える負担を要求したとき、又はかつて同様な行為を行なったと認められるとき。
                            </li>
                            <li>
                                宿泊しようとする者が、伝染病者であると明らかに認められるとき。
                            </li>
                            <li>
                                天災、施設の故障、その他やむを得ない事由により宿泊させることができないとき。
                            </li>
                            <li>
                                宿泊しようとする者が、泥酔者等で、他の宿泊者に著しく迷惑を及ぼすおそれがあると認められるとき、あるいは宿泊者が他の宿泊者に著しく迷惑を及ぼす言動をしたとき。
                            </li>
                        </ol>

                        <h2>宿泊客の契約解除権</h2>
                        <ol>
                            <li>宿泊客は、当ホテルに申し出て、宿泊契約を解除することができます。 </li>
                            <li>
                                当ホテルは、宿泊客がその責めに帰すべき事由により宿泊契約の解除した場合(第 3 条第 2 項の規定により当ホテルが申込金の支払期日を指定してその支払いを求めた場合で あって、その支払いより前に宿泊客が宿泊契約を解除したときを除きます。)は、違約金を申し受けます。ただし、当ホテルが第 4 条第 1 項の特約に応じた場合にあっては、そ の特約に応じるにあたって、宿泊客が宿泊契約を解除したときの違約金支払義務につい て、当ホテルが宿泊客に告知したときに限ります。
                            </li>
                            <li>
                                当ホテルは、宿泊客が連絡をしないで宿泊日当日の午後 10 時(あらかじめ到着予定時刻 が明示されている場合は、その時刻を 2 時間経過した時刻)になっても到着しないとき は、その宿泊契約は宿泊客により解除されたものとみなし処理することがあります。
                            </li>
                        </ol>

                        <h2>当ホテルの契約解除権</h2>
                        <ol>
                            <li>
                                当ホテルは、次に掲げる場合等においては、宿泊契約を解除することがあります。</li>
                            <ol>

                                <li>宿泊客が宿泊に関し、法令の規定、公の秩序若しくは善良の風俗に反する行為をするおそれがあると認められるとき、又は同行為をしたと認められるとき。</li>
                                <li>宿泊客が伝染病者であると明らかに認められるとき。</li>
                                <li>
                                    宿泊客が「暴力団員による不当な行為の防止等に関する法律」（平成4年3月1日施行）による指定暴力団および指定暴力団員等（以下「暴力団」および「暴力団員」とする）またはその関係者、その他反社会的勢力であるとき。
                                </li>
                                <li>宿泊客が、暴力団又は暴力団員が事業活動を支配する法人その他の団体であると認められたとき。</li>
                                <li>宿泊客が法人で、その役員のうちに暴力団員に該当する者のあると認められたとき。</li>
                                <li>宿泊客が宿泊施設もしくは宿泊施設職員（従業員）に対し、暴力、脅迫、恐喝、威圧的な不当要求を行い、あるいは、合理的範囲を超える負担を要求したとき、またはかつて同様な行為を行ったと認められるとき。
                                </li>
                                <li>宿泊客が泥酔等により他の利用者に迷惑をおよぼすおそれがあると認められるとき。あるいは、宿泊者が他の利用者に著しく迷惑をおよぼす言動をしたとき。</li>
                                <li>天災等不可抗力に起因する事由により宿泊させることができないとき。</li>
                                <li>消防用設備等に対するいたずら、その他当ホテルが定める利用規則の禁止事項(火災予防上必要なものに限る。)に従わないとき。


                            </ol>
                            <li>当ホテルが前項の規定に基づいて宿泊契約を解除したときは、宿泊客がいまだ提供を受けていない宿泊サービス等の料金はいただきません。

                        </ol>

                        <h2>宿泊の登録</h2>
                        <ol>
                            <li>
                                宿泊客は、宿泊日当日、当ホテルのフロントにおいて、次の事項を登録していただきます。
                            </li>
                            <ol>
                                <li>
                                    宿泊客の氏名、住所、電話番号、年齢、性別および職業
                                </li>
                                <li>
                                    外国人にあっては、国籍、旅券番号、入国地および入国年月日
                                </li>
                                <li>
                                    出発日および出発予定時刻
                                </li>
                                <li>
                                    その他当ホテルが必要と認める事項
                                </li>
                            </ol>
                            <li>宿泊客が第12条の料金の支払いを、旅行小切手、宿泊券、クレジットカード等通貨に代わり得る方法により行おうとするときは、あらかじめ、前項の登録時にそれらを呈示していただきます。
                                <li>
                        </ol>

                        <h2>客室の使用時間</h2>
                        <ol>

                            <li>
                                宿泊客が当ホテルの客室を使用できる時間は午後3時からご出発時の午前11時までとします。</li>
                            <li>
                                当ホテルは前項の規定にかかわらず、同項に定める時間外の客室の使用に応じることがあります。この場合は次に掲げる追加料金を申し受ける場合がございます。
                            </li>
                            <ol>
                                <li>
                                    午後1時までは当日料金の30%
                                </li>
                                <li>
                                    午後3時までは当日料金の50%
                                </li>
                                <li>
                                    それ以降は一泊料金
                                </li>
                            </ol>
                        </ol>

                        <h2>利用規則の遵守</h2>
                        <ol>
                            宿泊客には、当ホテル内においては、当ホテルが定めてホテル内に掲示した利用規則に従っていただきます。
                        </ol>

                        <h2>営業時間</h2>
                        当ホテルの主な施設等の営業時間は次のとおりとし、施設等の詳しい営業時間は備え付けパンフレット、各所の掲示、客室内のホテルインフォメーション等でご案内いたします。
                        <ol>
                            <li>
                                フロントキャッシャー等サービス時間<br>
                                <br> フロントサービス:24 時間
                                <br> コインランドリー:24 時間
                            </li>
                        </ol>
                        上記の時間は、必要やむを得ない場合には臨時に変更することがあります。

                        <h2>料金の支払い</h2>
                        <ol>
                            <li>
                                宿泊客が支払うべき宿泊料金等の内訳は、※1 に掲げるところによります。</li>
                            <li>
                                前項の宿泊料金等の支払いは、通貨又は当ホテルが認めた旅行小切手、宿泊券、クレジットカード等これに代わり得る方法により、宿泊客の到着の際又は当ホテルが請求した時、 フロントにおいて行っていただきます。
                            </li>
                            <li>
                                当ホテルが宿泊客に客室を提供し、使用が可能になったのち、宿泊客が任意に宿泊しなかった場合においても、宿泊料金は申し受けます。
                            </li>
                        </ol>


                        <h2>当ホテルの責任</h2>

                        <ol>
                            <li>
                                当ホテルは、宿泊契約およびこれに関連する契約の履行に当たり、又はそれらの不履行により宿泊客に損害を与えたときは、その損害を賠償します。ただし、それが当ホテルの責めに帰すべき事由によるものでないときは、この限りではありません。
                            </li>
                            <li>
                                当ホテルは消防設備の整備に努めているほか、万一の火災等に対処するため、旅館賠償責任保険に加入しております。
                            </li>
                        </ol>

                        <h2>契約した客室の提供ができないときの取扱い</h2>
                        <ol>
                            <li>
                                当ホテルは、宿泊客に契約した客室を提供できないときは、宿泊客の了解を得て、できる限り同一の条件による他の宿泊施設をあっ旋するものとします。

                            </li>
                            <li>
                                当ホテルは、前項の規定にかかわらず他の宿泊施設のあっ旋ができないときは、違約金相当額の補償料を宿泊客に支払い、その補償料は損害賠償額に充当します。ただし、客室が提供できないことについて、当ホテルの責めに帰すべき事由がないときは、補償料を支払いません。
                            </li>

                        </ol>

                        <h2>寄託物等の取扱い</h2>
                        <ol>
                            宿泊客がフロントにお預けになった物品又は現金並びに貴重品について、滅失、毀損等の損害が生じたときは、それが、不可抗力である場合を除き、当ホテルは、その損害を賠償します。ただし、賠償額は旅館賠償責任保険の範囲内とします。
                        </ol>
                        <h2>宿泊客の手荷物又は携帯品の保管</h2>
                        <ol>
                            <li>宿泊客の手荷物が、宿泊に先立って当ホテルに到着した場合は、その到着前に当ホテルが了解したときに限って責任をもって保管し、宿泊客がフロントにおいてチェックインする際お渡しします。
                            </li>
                            <li>
                                宿泊客がチェックアウトしたのち、宿泊客の手荷物又は携帯品が当ホテルに置き忘れられていた場合において、その所有者が判明したときは、当ホテルは、当該所有者に連絡をするとともにその指示を求めるものとします。ただし、所有者の指示がない場合又は所有者が判明しないときは、発見日を含め7日間保管し、その後最寄りの警察署に届けます。
                            </li>
                            <li>
                                前2項の場合における宿泊客の手荷物又は携帯品の保管についての当ホテルの責任は、第1項の場合にあっては前条第1項の規定に準じるものとします。
                            </li>
                        </ol>


                        <h2>駐車の責任</h2>
                        <ol>
                            宿泊客が当ホテルの駐車場をご利用になる場合、車両のキーの寄託の如何にかかわらず、当ホテルは場所をお貸しするものであって、車両の管理責任まで負うものではありません。ただし、駐車場の管理に当たり、当ホテルの故意又は過失によって損害を与えたときは、その賠償の責めに任じます。
                        </ol>
                        <h2>宿泊客の責任</h2>
                        <ol>
                            宿泊客の故意又は過失により当ホテルが損害を被ったときは、当該宿泊客は当ホテルに対し、その損害を賠償していただきます。 </ol>

                        <h3>※1 </h3>
                        宿泊料金等の内訳(第 12 条第 1 項関係)<br> 宿泊者が支払うべき料金
                        <br> 宿泊料金:1基本宿泊料〔室料(又は室料+朝食料)〕 追加料金:2飲食料〔又は追加飲食料(朝食以外の飲食料)〕及びその他の利用料金 税金:消費税 宿泊税<br> 備考 税法が改正された場合は、その改正された規定によるものとします。<br>

                        <h3>※2</h3>
                        客室内での喫煙が判明した際は、復旧費用として 5 万円を徴収させていただきます。

                        <h3>※3</h3>
                        違約金(第 6 条第 2 項関係)<br> 1) ご宿泊予定日の 2 日前までにご連絡いただいた場合・・・無料<br> 2) ご宿泊予定日の前日にご連絡いただいた場合・・・料金の 20%<br> 3) ご宿泊予定日の当日にご連絡いただいた場合・・・料金の 80%<br> 4) ご連絡無く不泊の場合・・・料金の 100%<br> 5) 団体でのご予約の場合は、下記一覧表のとおり、キャンセル料をいただきます。<br>
                        <table>
                            <tr>
                                <td rowspan=1 colspan=2>

                                </td>
                                <th rowspan=1 colspan=5>
                                    契約解除の通知を受けた日
                                </th>
                            </tr>

                            <tr>
                                <td colspan=1>契約申込人数</td>
                                <td colspan=1>
                                    不泊
                                </td>
                                <td>
                                    当日
                                </td>
                                <td>前日
                                </td>
                                <td>7日前
                                </td>
                                <td> 14日前
                                </td>
                                <td> 21日前
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    1〜9名まで

                                </td>
                                <td>100%</td>
                                <td>80%</td>
                                <td>20%</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>

                            <tr>
                                <td>10名～50名まで</td>
                                <td>100%</td>
                                <td>100%</td>
                                <td>80%</td>
                                <td>50%</td>
                                <td>30%</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>
                                    51名以上
                                </td>
                                <td>100%</td>
                                <td>100%</td>
                                <td>80%</td>
                                <td>80%</td>
                                <td>50%</td>
                                <td>10%</td>
                            </tr>

                        </table>
                        （注）
                        <ol>
                            <li>パーセント（%）は、予約宿泊料に対する違約金の比率です。
                            </li>
                            <li>

                                契約日数が短縮した場合は、その短縮日数にかかわりなく、1日分（初日）の違約金を収受します。
                            </li>
                            <li>
                                宿泊客が無連絡で宿泊当日の午後 10 時(あらかじめ到着予定時刻が明示されている場合は、その時刻を 2 時間経過した時刻)になって未到着の場合は、その宿泊予約は申込者により解除されたものとみなし違約金を収受します。
                            </li>
                            <li>
                                なおご宿泊プラン、団体宿泊プランの内容により上記規定以外のキャンセル料を申し受ける場合がございます。
                            </li>
                            <li>
                                天災等の理由により、ホテルまでの交通機関が不通の際は、キャンセル料をいただかない場合もございます。
                            </li>
                        </ol>

                        <h2>約款の変更等</h2>
                        <ol>
                            <li>
                                当ホテルは、この約款、利用規則または預り品規定（以下本条において「この約款等」といいます。）を任意に変更することができるものとします。
                            </li>
                            <li>
                                この約款等の変更は、変更後のこの約款等を当ホテル所定のウェブサイトに掲示し、原則として当該掲示から30日の周知期間を経て、当ホテルが定めた効力発生時期にその効力を生じるものとします。
                            </li>
                        </ol>
                    </div>
                    <div class="keiyaku_en" *ngIf="lang=='eng'">

                        <h1>General Terms & Conditions for Accommodation Contracts</h1>
                        <h2>Scope of Application</h2>
                        <ol>
                            <li>
                                Contracts for Accommodation and related agreements to be entered into between this Hotel and the Guest to be accommodated shall be subject to these Terms and Conditions. Any particulars not provided for herein shall be governed by laws and regulations
                                and/or generally accepted practices.
                            </li>

                            <li>
                                Should the Hotel enter into a special contract with the Guest, insofar as that special contract does not violate any laws, regulations or generally accepted practices, the special contract shall take precedence over the provisions of these Terms and Conditions.

                            </li>
                        </ol>

                        <h2>Application for Accommodation Contracts</h2>
                        <ol>
                            <li>
                                A Guest who intends to make an application for an Accommodation Contract with the Hotel shall notify the Hotel of the following particulars:
                            </li>
                            <ol>
                                <li>
                                    Name of the Guest(s)
                                </li>
                                <li>
                                    Date of accommodation and estimated time of arrival
                                </li>
                                <li>
                                    Accommodation Charges (based, in principle, on the Basic Accommodation Charges listed in the Attached Table No.1.)
                                </li>
                                <li>
                                    Other particulars deemed necessary by the Hotel.
                                </li>
                            </ol>
                            <li>
                                Should a Guest request, during his stay, an extension of the accommodation beyond the date in subparagraph (2) of the preceding paragraph, it shall be regarded as an application for a new Accommodation Contract at the time such request is made.
                            </li>
                        </ol>

                        <h2>Conclusion of Accommodation Contracts, Etc.</h2>
                        <ol>
                            <li>
                                A Contract for Accommodation shall be deemed to have been concluded when the Hotel has duly accepted the application as stipulated in the preceding Article. However, the same shall not apply where it has been proved that the Hotel has not accepted the
                                application.

                            </li>
                            <li>
                                When a Contract for Accommodation has been concluded in accordance with the provisions of the preceding paragraph, the Guest is requested to pay an accommodation deposit, fixed by the Hotel, within the limits of Basic Accommodation Charges covering the
                                Guest's entire period of stay (3 days when the period of stay exceeds 3 days) by the date specified by the Hotel.
                            </li>
                            <li>
                                The deposit shall be first used for the Total Accommodation Charges to be paid by the Guest, then secondly for the cancellation charges under Article 6 and thirdly for the reparations under Article 18 as applicable, and the remainder, if any, shall be
                                refunded at the time of the payment of the Accommodation Charges as stated in Article 12.

                            </li>
                            <li>
                                When the Guest has failed to pay the deposit by the date as stipulated in Paragraph 2, the Hotel shall treat the Accommodation Contract as invalid. However, the same shall apply only in the case where the Guest is thus informed by the Hotel when the period
                                of payment of the deposit is specified.

                            </li>
                        </ol>

                        <h2>Special Contracts Requiring No Accommodation Deposit</h2>
                        <ol>
                            <li>
                                Notwithstanding the provisions of Paragraph 2 of the preceding Article, the Hotel may enter into a special contract requiring no accommodation deposit after the Contract has been concluded as stipulated in the same paragraph.
                            </li>
                            <li>
                                In the event that the Hotel has not requested the payment of a deposit as stipulated in Paragraph 2 of the preceding Article and/or has not specified the date of the payment of the deposit at the time the application for an Accommodation Contract has
                                been accepted, it shall be treated as if the Hotel has accepted a special contract as prescribed in the preceding paragraph.
                            </li>
                        </ol>

                        <h2>Refusal of Accommodation Contracts</h2>
                        The Hotel may not accept the conclusion of an Accommodation Contract in the following cases:
                        <ol>
                            <li>
                                When the application for accommodation does not conform with the provisions of these Terms and Conditions.
                            </li>
                            <li>
                                When the Hotel is fully booked and no room is available
                            </li>
                            <li>
                                When the Guest seeking accommodation is deemed liable to conduct himself in a manner that will contravene the laws or act against the public order or good morals in regard to his accommodation.
                            </li>
                            <li>
                                (4) When the Guest seeking accommodation is an organized crime group ("Boryokudan"), a member of an organized crime group ("Boryokudanin"), a person related to an organized crime group, or any type of unlawful group.
                            </li>
                            <li>
                                When the guest seeking accommodation is as a company or an organization, which is directly or indirectly managed by Boryokudan or a Boryokudan-in.

                            </li>
                            <li>
                                When the Guest seeking accommodation is a corporate customer, of which a board member is deemed a Boryokudan-in.
                            </li>
                            <li>
                                When the Guest seeking accommodation annoys other guests.

                            </li>
                            <li>
                                When the Guest seeking accommodation threatens violence, uses threats, extortion or makes an unreasonable or coercive request to the Hotel or a staff member. Or when the guest seeking accommodation makes an unreasonable request which is deemed significantly
                                unfair or when he/she is deemed a person who has previously acted in such a manner.

                            </li>
                            <li>
                                When the Guest seeking accommodation can be clearly detected as carrying infectious disease.

                            </li>
                            <li>
                                When the Hotel is unable to provide accommodation due to natural calamities, disfunction of the facilities and/or other unavoidable causes.

                            </li>
                            <li>
                                When the Guest seeking accommodations is obviously intoxicated and could cause annoyance to other guest or when the Guest is behaving in such a manner as to be an annoyance to other guests.

                            </li>
                            <li>
                                When the Hotel recognizes that the Guest is behaving suspiciously or when the Hotel has any justifiable reason to refuse the Guest’s request for accommodation.
                            </li>

                        </ol>

                        <h2>Right to Cancel Accommodation Contracts by The Guest</h2>
                        <ol>
                            <li>The Guest is entitled to cancel the Accommodation Contract by so notifying the Hotel. </li>
                            <li>
                                Should the Guest cancel the Accommodation Contract in whole or in part due to causes for which the Guest is liable (except in the case when the Hotel has requested the payment of the deposit during the specified period as prescribed in Paragraph 2 of
                                Article 3 and the Guest has cancelled before payment), the Guest shall pay cancellation charges as listed in the Attached Table No.2. However, should a special contract, as prescribed in Paragraph 1 of Article 4 be in effect,
                                the same shall apply only when the Guest is informed of the obligation for payment of the cancellation charges in case of cancellation by the Guest.
                            </li>
                            <li>
                                In the case when the Guest does not appear by 8:00 p.m. of the accommodation date (2 hours after the expected time of arrival if the Hotel has been notified) without an advance notice, the Hotel may regard the Accommodation Contract as being cancelled
                                by the Guest.
                            </li>
                        </ol>

                        <h2>Right to Cancel Accommodation Contracts by The Hotel</h2>
                        <ol>
                            <li>
                                The Hotel may cancel the Accommodation Contract under any, but not limited to the following cases:
                            </li>
                            <ol>
                                <li>
                                    When the Guest is deemed liable to conduct and/or have conducted himself in a manner that will contravene the laws or act against the public order and good morals in regard to his accommodation.
                                </li>
                                <li>
                                    When the Guest can be clearly detected as carrying any infectious disease.
                                </li>
                                <li>

                                    When the cancellation of Accommodation Contract is subject to the laws or prefectural ordinance.
                                </li>
                                <li>

                                    When the guest is the identified organized crime group or a member of the identified organized crime group (called “Boryokudan” and “Boryokudan-in” respectively) according to the definition of the “Law preventing unjustifiable actions by an organized
                                    crime group” (enforced on March 1, 1992), or a person related to them or a member of antisocial forces.
                                </li>
                                <li>

                                    When the guest is a company or an organization, which is directly or indirectly managed by Boryokudan or a Boryokudan-in.
                                </li>
                                <li>

                                    When the guest is a corporate customer, of which a board member is deemed a Boryokudan-in.
                                </li>
                                <li>

                                    When the guest exercises violence, intimidation or extortion or makes an unreasonable request in coercive manner to the Hotel or Hotel’s personnel (employee). Or when the guest makes an unreasonable request which is deemed exceeding the reasonable level
                                    or when he/she is deemed to be the person who has previously acted in such manner.
                                </li>
                                <li>

                                    When the guest is heavily drunk or in the similar condition and deemed likely to annoy other guests or when he/she has actually annoyed other guests.
                                </li>
                                <li>

                                    When the Hotel is unable to provide accommodation due to natural calamities and/or other causes of force majeure.
                                </li>
                                <li>

                                    When the Guest does not observe prohibited actions such as smoking in bed, mischief to the fire-fighting facilities and other promotions of the Use Regulations stipulated by the Hotel (restricted to particulars deemed necessary in order to avoid the causing
                                    of fires).
                                </li>

                            </ol>
                            <li>
                                Should the Hotel cancel the Accommodation Contract in accordance with the preceding paragraph, the Hotel shall not be entitled to charge the guest for any services during the contractual period which he has not received.
                            </li>

                        </ol>

                        <h2>Registration </h2>
                        <ol>
                            <li>
                                The Guest shall register the following particulars at the front desk of the Hotel on the day of accommodation:
                            </li>
                            <ol>
                                <li>
                                    Name, address, phone number, age, sex and occupation

                                </li>
                                <li>
                                    Except Japanese, nationality, passport number, port and date of entry in Japan

                                </li>
                                <li>
                                    Date and estimated time of departure

                                </li>
                                <li>
                                    Other particulars deemed necessary by the Hotel

                                </li>
                            </ol>
                            <li>
                                Should the Guest intend to pay his Accommodation Charges prescribed in Article 12 by any means other than Japanese currency, such as traveler's cheques, coupons or credit cards, these credentials shall be shown in advance at the time of the registration
                                prescribed in the preceding Paragraph.

                                <li>
                        </ol>

                        <h2>Occupancy Hours of Guest Rooms</h2>
                        <ol>
                            <li>
                                The Guest is entitled to occupy the contracted guest room of the Hotel from 3:00 p.m. to 11:00 a.m. of the next day. However, in the case when the Guest is accommodated continuously, the Guest may occupy it all day long, except for the days of arrival
                                and departure.
                            </li>
                            <li>
                                The Hotel may, not withstanding the provisions prescribed in the preceding Paragraph, permit the Guest to occupy the room beyond the time prescribed in the same Paragraph.In this case, following extra charges shall be applied.
                            </li>
                            <ol>
                                <li>
                                    11:00 a.m. - 1:00 p.m. 30% of room charge
                                </li>
                                <li>
                                    1:00 p.m. - 3:00 p.m. 50% of room charge
                                </li>
                                <li>
                                    After 3:00 p.m. 100% of room charge
                                </li>
                            </ol>
                        </ol>

                        <h2>Observance of Use Regulations</h2>
                        The Guest shall observe the Rules and Regulations established by the Hotel, which are posted within the premises of the Hotel.


                        <h2>Business Hours</h2>
                        The business hours of the main facilities, etc. of the Hotel are as follows, and those of other facilities, etc. shall be notified in detail by notices displayed in each place, service directions in guest rooms and others.
                        <ol>
                            <li>
                                Service hours of the front desk, cashier's desk, etc.<br> 　　 Open 24 hours<br> 　　 Front desk service: 24 hours
                            </li>
                            <li>
                                Service hours for dining, drinking, etc. <br> Breakfast Service: 7:00 – 10:30 (Restaurant, 3rd floor)

                            </li>
                        </ol>
                        The business hours specified in the preceding paragraph are subject to temporary changes due to unavoidable causes. In such a case, the Guest shall be informed by appropriate means.

                        <h2>Payment of Accommodation Charges</h2>
                        <ol>
                            <li>
                                The breakdown of the Accommodation Charges, etc. that the Guest shall pay is as listed in the Attached Table No.1.
                            </li>
                            <li>
                                Accommodation Charges, etc. as stated in the preceding paragraph shall be paid with Japanese currency or by any means other than Japanese currency such as traveler's cheques, coupons or credit cards recognized by the Hotel at the front desk at the time
                                of the arrival of the Guest or upon request by the Hotel.
                            </li>
                            <li>
                                Accommodation Charges shall be paid even if the Guest voluntarily does not utilize the accommodation facilities provided for him by the Hotel and are at his disposal.
                            </li>
                        </ol>


                        <h2>Liabilities of The Hotel</h2>

                        <ol>
                            <li>
                                The Hotel shall compensate the Guest for the damage if the Hotel has caused such damage to the Guest in the fulfillment or the nonfulfillment of the Accommodation Contract and/or related agreements. However, the same shall not apply in case where such
                                damage has been caused due to reasons for which the Hotel is not liable.
                            </li>
                            <li>
                                The Hotel takes all possible measures to maintain the buildings fire fighting equipment. The Hotel is also covered by Hotel Liability Insurance for fire and/or other disasters.
                            </li>
                        </ol>

                        <h2>Handling When Unable to Provide Contracted Rooms</h2>
                        <ol>
                            <li>
                                The Hotel shall, when unable to provide contracted rooms, arrange accommodation of the same standard elsewhere for the Guest insofar as practicable with the consent of the Guest.


                            </li>
                            <li>
                                When arrangement for other accommodation can not be made, not withstanding the provisions of the preceding paragraph, the Hotel shall pay the Guest a compensation fee equivalent to the cancellation charges and the compensation fee shall be applied to
                                the reparations. However, when the Hotel cannot provide accommodation due to causes for which the Hotel is not liable, the Hotel shall not be liable to compensate the Guest.
                            </li>

                        </ol>

                        <h2>Handling of Deposited Articles </h2>
                        <ol>
                            <li>
                                The Guest and the recipient of the deposited articles shall observe the Provisions for Articles in Custody established by the Hotel, which are posted within the premises of the Hotel.
                            </li>
                            <li>
                                The Hotel shall compensate the Guest for the damage when loss, breakage or other damage is caused by the Hotel to the goods, cash or valuables deposited at the front desk by the Guest, except in the case when this has occurred due to causes of force majeure,
                                provided, however, that the amount of compensation is limited to the coverage amount of Innkeepers General Liability Insurance. In addition, if the deposited article is cash or other valuables and the Guest failed to inform
                                the Hotel of the type and value of the article in advance, the Hotel will compensate the Guest up to a maximum of 150,000 yen.
                            </li>
                        </ol>

                        <h2>Custody of Baggage and/or Belongings of The Guest</h2>
                        <ol>
                            <li>
                                When the baggage of a Guest is brought into the Hotel before his arrival, the Hotel shall be liable to keep it only should request have been accepted by the Hotel. The baggage shall be handed over to the Guest at the front desk at the time of his check-in
                            </li>
                            <li>
                                When the baggage or belongings of the Guest are found left after this check-out, and the ownership of the article is confirmed, the Hotel shall inform the owner of the article left and ask for further instructions. When no instruction is given to the
                                Hotel by the owner or when the ownership is not confirmed, the Hotel shall keep the article for 7 days, including the day it is found, and after this period the Hotel shall turn it over to the nearest police station.

                            </li>
                            <li>
                                The Hotel's liability in regard to the custody of a Guest's baggage and belongings in the case of the preceding two paragraphs shall be assumed in accordance with the provisions of Paragraph 1 of the preceding article in the case of Paragraph 1, and with
                                the provisions of Paragraph 2.

                            </li>
                        </ol>

                        <h2>Liability of the Guest</h2>
                        The Guest shall compensate the Hotel for the damage caused through intention or negligence on the part of the Guest.


                        <h3>Attached Table of Charges 1</h3>
                        TABLE OF CHARGES FOR LODGING AND MISCELLANEOUS EXPENSES<br> [Regarding Item 1 of Article 2 and Item 1 of Article 12


                        <table>

                            <tr>
                                <td colspan=2>Total Amount to be paid by a Registered Guest</td>
                            </tr>
                            <tr>

                                <td>Accommodation charges</td>
                                <td>Basic Accommodation Charges (Room Charge or Room Charge + Breakfast Charge [when included in the basic room charge])</td>
                            </tr>
                            <tr>
                                <td>Additional charges</td>
                                <td>Other relevant charges.</td>
                            </tr>
                            <tr>
                                <td>Tax</td>
                                <td>Consumption Tax</td>
                            </tr>
                        </table>
                        Notes: In the event the relevant tax laws are modified, the latest modified version shall be applicable to all charges listed above.





                        <h3>
                            Attached Table of Charges 2
                        </h3>


                        <table>
                            <tr>
                                <th colspan=1 rowspan=3>
                                    Date when Cancellation of Contract is Notified
                                </th>
                                <th colspan=3>Contracted Number of Guests</th>
                            </tr>
                            <tr>
                                <td>
                                    Individuals
                                </td>
                                <td colspan=2>
                                    Group
                                </td>
                            </tr>
                            <tr>

                                <td>
                                    1 to 14
                                </td>
                                <td>15 to 99</td>
                                <td>
                                    100 and more
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    No Show
                                </td>
                                <td>100%</td>
                                <td>100%</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td>
                                    Accommodation Day
                                </td>
                                <td>80%</td>
                                <td>80%</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td>
                                    1 Day Prior to Accommodation Day
                                </td>
                                <td>20%</td>
                                <td>20%</td>
                                <td>80%</td>
                            </tr>
                            <tr>
                                <td>
                                    9 Days Prior to Accommodation Day
                                </td>
                                <td>-</td>
                                <td>10%</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>2 20 Days Prior to Accommodation Day
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>10%</td>
                            </tr>
                        </table>

                        Cancellation Charge for Hotels (Ref. Paragraph 2 of Article 6) Remarks:
                        <ol>
                            <li>
                                The percentage signifies the rate of cancellation charge to the day of the rate.

                            </li>
                            <li>
                                When the number of days contracted is shortened, a cancellation for the first day shall be paid by the Guest regardless of the number of days shortened.

                            </li>
                            <li>
                                When the Hotel receive no contact from the Guest until 10 pm on arrival day (or 2 hours past from ETA), the Hotel shall regard the reservation is cancelled by the Guest, and release the reservation.

                            </li>
                            <li>
                                Different cancellation charges may apply with some accommodation plans. Please check your package plan for details.

                            </li>
                            <li>
                                When the transportation to the Hotel is cut off by a disaster, the cancellation charge shall not be charged.
                            </li>
                        </ol>




                        <h2>Liability In Regard to Parking</h2>
                        <ol>
                            <li>The Hotel shall not be liable for the custody of the vehicle of the Guest when the Guest utilizes the parking lot within the premises of the Hotel, as it shall be regarded that the Hotel simply offers the space for parking,
                                whether the key of the vehicle has been deposited to the Hotel or not. However, the Hotel shall compensate the　Guest for the damage caused through intention or negligence on the part of the Hotel in regard to the management
                                of the parking lot.</li>
                        </ol>


                        <h2>Amendments to Terms and Conditions</h2>
                        <ol>
                            <li>
                                These General Terms and Conditions for Accommodation Contracts, Rules of Use, Provisions for Articles in Custody (collectively, the “Terms and Conditions” in this article) are subject to change from time to time in the Hotel’s discretion.
                            </li>
                            <li>
                                The Hotel post amendments to the Terms and Conditions on the Hotel’s specified website, and as a general rule, the amended Terms and Conditions shall become effective on a date established by the Hotel with a 30-day prior notice after the post.
                            </li>
                        </ol>


                    </div>
                </div>

                <div class="keiyaku_wrapper_1" *ngIf="focusIndex == 1">
                    <div class="keiyaku " *ngIf="lang=='jpn'">
                        <h1>利用規約</h1>

                        ホテルの公共性とお客様に安全かつ快適にお過ごしいただくため、宿泊約款第10条に基づいて下記の利用規則を定めておりますので、遵守して頂きますようお願い申し上げます。この規則をお守りいただけないときは、ご宿泊またはホテル内諸施設のご利用をお断り申し上げ、かつ責任をお取りいただくこともございます。
                        <h3>安全と保安上お守りいただきたいことについて</h3>
                        <ol>
                            <li>
                                客室内で、炊事用などの器具をご使用にならないでください。</li>
                            <li>客室は全て禁煙です。喫煙はご遠慮ください。</li>
                            <li>花火、線香、ローソクなど、火災の原因となるような物品をご使用にならないでください。</li>
                            <li>客室からの避難経路図は各客室ドア内側に表示しておりますのでご確認ください。</li>
                            <li>ご滞在中、お部屋から出られるときは、施錠をご確認ください。（ドアを閉めると自動的に鍵がかかります。）</li>
                            <li>
                                ご在室中、特にご就寝の時は、ドアの内鍵とドアフックをお掛けください。来訪者があったときは、不用意に開扉なさらずにドアフックを掛けたまま開扉されるか、ドアスコープでご確認ください。万一、不審者と思われる場合は直ちにフロントへご連絡ください。
                            </li>
                            <li>ご来訪者と客室内でのご面会はご遠慮ください。</li>
                            <li>到着時にご宿泊者の氏名、住所、電話番号、職業及び諸事項の記入をお願いいたします。</li>
                            <li>館内利用により生じたゴミは、所定の場所（室内ゴミ箱など）へ破棄お願いいたします。</li>
                            <li>客室内・廊下・ロビー、その他館内にて、他のお客様に嫌悪感、迷惑を及ぼす行為、大声で騒ぐなどの行為はご遠慮ください。</li>
                            <li>館内での事故・火災など発見した際は、フロントへご連絡ください。
                                <br> 　　　　携帯電話などから：ホテルオリエンタルエクスプレス福岡天神「092-401-1905」

                        </ol>

                        <h3>貴重品について</h3>
                        現金・貴重品の保管は、客室に備えつけの金庫をご利用いただきますようお願いいたします。 客室での現金・貴重品の紛失に関しては、ホテルは責任を負いかねますので、あらかじめご了承ください。

                        <h3>お預かり品について</h3>
                        原則として、お忘れ物は、特にご指定のない限り、発見日を含めて7日間保管し、その後法令の定める手続きを取らせて頂きます。

                        <h3>お支払いについて</h3>
                        <ol>
                            <li>
                                ご到着の際、当ホテルが定めるお預かり金(前金)を申し受けております。ご了承ください。</li>
                            <li>ご滞在中、フロントから勘定書の提示がございましたら、その都度、お支払いください。</li>
                            <li>旅行小切手以外の小切手によるお支払いおよび両替は、固くお断りいたします。</li>
                            <li>航空機、列車、遊覧バスなどの切符代、タクシー代、郵便切手代、お荷物送付などのお立替はお断りいたします。</li>
                        </ol>

                        <h3>当ホテル施設内で下記の行為はお断りいたします。</h3>
                        <ol>
                            <li>ホテル内に他のお客様の迷惑になるようなものをお持込みにならないでください。
                            </li>
                            <ol>
                                <li>
                                    犬、猫、小鳥、その他の動物ペット類全般（介助犬は除く）</li>
                                <li>発火または引火しやすい火薬や揮発油類および危険性のある製品</li>
                                <li>悪臭を発するもの</li>
                                <li>銃砲刀剣類など法令による所持禁止物件</li>
                                <li>著しく多量な物品</li>
                                <li>その他法令で所持を禁じられているもの</li>
                            </ol>
                            <li>
                                ホテル内で、賭博や風紀、治安を乱すような行為、高声、放歌、楽器演奏など他のお客様に迷惑になったり、嫌悪感を与えたりするような行為はしないでください。</li>
                            <li>客室やロビーでの営業行為、また事務所など、宿泊以外の目的に使用にしないでください。</li>
                            <li>予約またはチェックインの際にお客様からお申し出いただいていない方は、客室内への立ち入りをご遠慮ください。</li>
                            <li>ホテル内で広告・宣伝物の配付ならびに貼布、物品の販売等をしないでください。</li>
                            <li>ホテル内の施設、備品を所定の場所、用途以外に、また現状を著しく変更して利用しないでください。</li>
                            <li>ホテルの外観を損なうようなものを窓に掛けたり、窓側に陳列したりしないでください。</li>
                            <li>ロビーや廊下などに所持品を放置しないでください。またスリッパ、下駄の使用はご遠慮ください。</li>
                            <li>ホテル外からの飲食物のご注文はご遠慮ください。</li>
                            <li>パジャマ、スリッパで廊下、ロビーなど客室以外の諸施設を利用しないでください。</li>
                            <li>緊急事態、あるいはやむを得ない事情が発生しない限り、非常階段、屋上、 塔屋、機械室などの施設には立ち入らないでください。</li>
                            <li>未成年者のみでのご宿泊は、特に保護者の許可がない限りはお断りいたします。</li>
                            <li>不可抗力以外の事由により、建造物、備品、その他ホテルの物品を損傷、汚染あるいは紛失させた場合、相当額を弁償していただく事があります。</li>

                        </ol>

                        <h3>預り品規定</h3>
                        <ol>
                            <li>
                                （適用）<br> 宿泊約款第15条の規定に基づき当ホテルは、当ホテルの宿泊客に限り、本規定の定めるところにより、物品、手荷物等をお預り致します。
                            </li>
                            <li>（お預り期間）</li>
                            <ol>
                                <li>
                                    お預り期間は、当ホテルがお預り品をお預りした日からお受取りご指定日までとします。</li>
                                <li>お受取りご指定日は、当ホテルがお預り品をお預りした日から7日以内に限ります。</li>
                                <li>お受取り日のご指定がない場合は、お預り期間はお預りの日から7日間とします。</li>
                            </ol>

                            <li>（お預り品）<br> 現金、宝石、貴重品、危険物、腐敗あるいは破損しやすいもの、動植物、虫害を受けやすい羊毛、毛皮製品等はお預りできません。
                            </li>
                            <li>
                                （お受取り人）<br> お預り品のお受取り人は、お預けのご依頼人又はその方がお受取り人としてご指定された第三者とします。
                            </li>
                            <li>
                                （お受取り人の確認）</li>
                            <ol>
                                <li>
                                    お受取り人又は権限を与えられた第三者は、お預り品のお受取りを請求なされる際、当ホテルの係の者にお預り証をご提示ください。</li>
                                <li>お受取り人がお預けのご依頼人によって指定された第三者の場合は、お預り証のご提示は不要ですが、正当なお受取り人であることを示すもののご提示を求めることがあります。</li>
                                <li>係員は相応の注意をもって、お受取り人の同一性を確認し、お預り品をお返しします。この場合、当ホテルはお預かり品に関しての責任を免れるものとします。</li>
                            </ol>

                            <li>（損害の賠償）</li>
                            <ol>
                                <li>
                                    一般に不可抗力とされている事由によるお預り品の紛失、毀損、変質、その他の損害に対しては、当ホテルはその責任を負いません。</li>
                                <li>お預り品の毀損、変質その他ご依頼人の責めに帰すべき事由により当ホテル又は第三者が損害を受けたときは、その損害を賠償していただきます。</li>
                            </ol>


                            <li>（お預り品処分）</li>
                            <ol>
                                <li>
                                    お預り期間終了後7日以内にお預り品のお受取りがない場合は、当ホテルはお預り品を別途通常の管理をし、一般に適当と認められる方法により処分できるものとします。かかる処分が困難な場合、当ホテルは当該お預り品を廃棄することができるものとします。
                                </li>
                                <li>
                                    前項の処分に要する費用はご依頼人の負担とします。ただし、処分によって得られた代金は、処分の費用に充当することができるものとします。
                                </li>
                            </ol>

                            <li>（緊急措置）</li>
                            当ホテルは、次のような事態が生じたときは、臨機の措置をとることができるものとします。
                            <ol>
                                <li>
                                    司法機関の要求によりお預り品の開披を求められたとき。
                                </li>
                                <li>
                                    火災、お預り品の異変、その他緊急を要すると認められたとき。
                            </ol>

                            <li>（正文）<br> 本規定は日本語と英語で作成されますが、両文の間に不一致又は相違があるときは、すべて日本文を正文とします
                            </li>

                            <li>（管轄及び準拠法）<br> 本規定に関して生じる一切の紛争については、当ホテルの所在地を管轄する日本の裁判所において、日本の法令に従い解決されるものとします。
                            </li>

                        </ol>

                    </div>
                    <div class="rule_of_use_en" *ngIf="lang=='eng'">



                        <h1>
                            Rules of Use
                        </h1>
                        <h3>
                            For Personal Safety
                        </h3>
                        <ol>

                            <li>
                                Although the outside doorknob of every guest room locks automatically, you are requested to verify that the door is completely locked whenever leaving your room.
                            </li>
                            <li>
                                To ensure a speedy and safe evacuation in the event of fire or natural calamity, you are requested to refer to the instructions posted on the inside of your room door to acquaint yourself with the location of the "Emergency Exits" closest to your room.
                            </li>
                            <li>
                                Upon entering your room, please verify that the safety lock is set and that the door latch is in place. In the event someone seeks entry, it is strongly recommended that before opening the door you look through the scope set into the door to ensure that
                                the visitor is someone known to you or someone who can properly be identified.
                            </li>
                            <li>
                                You are requested to notify the Front Desk immediately in the event of any unusual occurrences or if any unidentified persons are seen to be loitering around.
                            </li>
                            <li>
                                Meeting with visitors in your room is against hotel policy. For that purpose, please use the lobby or any of the public facilities of the hotel.
                            </li>
                            <li>
                                Please fill in your name, address, telephone number, place of employment, and other miscellaneous information requested by the Hotel.
                            </li>
                            <li>
                                Please dispose of the trash generated by using the facility in the designated place (indoor trash can, etc.).\
                            </li>
                            <li>
                                Please refrain from acts that cause disgust, inconvenience, or make loud noises to other guests in the guest rooms, corridors, lobbies, and other facilities.
                            </li>
                            <li>
                                If you find an accident or fire in the building, please contact the front desk.<br>From mobile phones, etc .: Hotel Oriental Express Fukuoka Tenjin "092-401-1905"
                            </li>




                        </ol>

                        <h3>To Prevent Fire</h3>
                        <ol>
                            <li>
                                Other than those provided by the hotel, the use of cooking utensils is prohibited.
                            </li>
                            <li>
                                Floors except for 3F, 4F, 11F and public areas are non-smoking. Please refrain from smoking in areas other than smoking guest rooms.
                            </li>
                            <li>
                                Fireworks, incense sticks, candles, and other inflammable items are specifically forbidden to be used within the hotel.
                            </li>
                        </ol>


                        <h3>Valuables</h3>
                        To prevent the loss of money, jewelry, or other valuables, it is strongly recommended that they are placed in the safety deposit boxes that are available in your guestroom. We do not take any responsibility for lost valuables.

                        <h3>Unclaimed Articles</h3>

                        Except that there is a forwarding address or specific instructions, when the hotel receives disposal or unclaimed articles, these will be kept for a maximum of 7 days. Following that time, articles remaining unclaimed will be handled in accordance with
                        the laws to the local Police Department.


                        <h3>Payment of Hotel Charges</h3>
                        <ol>

                            <li>
                                The hotel has the right to request a deposit upon checking-in.
                            </li>
                            <li>
                                Accounts presented for payment must be settled immediately.
                            </li>
                            <li>
                                With the exception of "Travelers Cheques," no cheques will be accepted for payment or changed into currency.
                            </li>
                            <li>
                                The hotel assumes absolutely no responsibility for the payment of any incidental guest expenses such as but not limited to shopping charges, tickets, taxi fares, or postage.
                            </li>
                        </ol>

                        <h3>Miscellaneous</h3>
                        <ol>
                            <li>
                                The following articles are forbidden in the hotel:
                            </li>
                            <ol>
                                <li>
                                    Animals and birds. (excluding service dogs)
                                </li>
                                <li>
                                    Gasoline, explosives, or any other inflammables.
                                </li>
                                <li>
                                    Offensive smelling items.
                                </li>
                                <li>
                                    Swords and firearm and other weapons.
                                </li>
                                <li>
                                    Excessively bulky objects.
                                </li>
                                <li>

                            </ol>
                            <li>
                                Gambling and similar offenses against public order are prohibited. To avoid disturbing other guests, you are requested to avoid making loud noises or singing loud.
                            </li>
                            <li>
                                Without the specific consent of the hotel management, you may use neither your room nor the lobby as an office, for private parties, or for any other such activity.
                            </li>
                            <li>
                                Persons not registered by the Guest during reservation and or check in is prohibited to enter the Guest room.
                            </li>
                            <li>
                                The distribution of advertising material or solicitous literature is prohibited within the hotel.
                            </li>
                            <li>
                                Without the specific consent of the hotel management, the rearrangement of hotel facilities and/or furnishings is prohibited.
                            </li>
                            <li>
                                To maintain an attractive exterior appearance, it is against hotel policy for guests to display objects in windows or to hang items from them.
                            </li>
                            <li>
                                Guests are requested to ensure they do not leave their belongings either in the lobby or in the corridors. Please note that slippers, "geta" Japanese wooden clogs, are not appropriate with in the hotel. Umbrellas may either be placed in an umbrella box
                                at the entrance or deposited in the cloak room.
                            </li>
                            <li>
                                The ordering of meals and drinks and delivering these from outside the hotel, is not permitted.
                            </li>
                            <li>
                                It is against hotel policy to go out of your room dressed in a pajama or wearing slippers.
                            </li>
                            <li>
                                Except in the case of emergency or for unavoidable reasons, the use of emergency exits and entry into areas marked "Private" is not permitted.
                            </li>
                            <li>
                                Except with the specific consent of either their parents or guardians, minors (under 18 years old) are not permitted to stay at this hotel.
                            </li>
                            <ol>
                                <li>
                                    Any and all damages you may inflict upon any part of the hotel.
                                </li>
                                <li>
                                    Contamination for which you bear responsibility.
                                </li>
                                <li>
                                    Any loss suffered by the hotel for any acts, omissions or negligence caused by Guest.
                                </li>
                                <li>
                            </ol>

                        </ol>



                        <h3>Provisions for Articles in Custody</h3>

                        <h4>
                            Article 1. Applications
                        </h4>
                        <ol>
                            Hotel takes custody of those articles and belongings of guest who is staying or to be staying at this Hotel under herein contained provisions pursuant to paragraph Articles 15 of Terms and Conditions for Accommodation Contracts.
                        </ol>


                        <h4>
                            Article 2. Period of Custody</h4>
                        <ol>
                            <li>
                                The period of custody shall run from the day upon which an article is put into custody of this Hotel until designated date of return
                            </li>
                            <li>
                                The designation date of return shall be within 7 days from the day upon which the article was put into custody of this Hotel.
                            </li>
                            <li>
                                If no such designation of date is made, the period of custody shall run for 7 days beginning on the day the article was put into custody.
                            </li>
                        </ol>

                        <h4>
                            Article 3. Prohibited Articles</h4>
                        <p>
                            Articles such as but not limited to, jewelry, valuables, dangerous objects, perishables, fragile articles, plants, animals and wooden goods can not be put into custody.
                        </p>
                        <h4>
                            Article 4. Claimant</h4>
                        <p>
                            The claimant of the article in custody be either the guest who requested custody or a person by the guest who requested custody.
                        </p>

                        <h4>
                            Article 5. Confirmation of Claimant</h4>
                        <ol>
                            <li>
                                The claimant shall submit the Claim Certificate to the clerk of this Hotel in charge of the article in custody at the time he/she claims the return of the article. In the event that the claimant is a third party appointed by the guest who requested custody
                                of the article, submission of the Claim Certificate will not be necessary. However, this Hotel may require him/her to submit proof that he/she is correct claimant. The clerk shall in his/her reasonable determination release
                                the article to the person claiming the article in custody. The Hotel shall thereafter have no further responsibility with respect to the article claimed.
                            </li>
                        </ol>

                        <h4>
                            Article 6. Compensation for Damage</h4>
                        <ol>
                            <li>
                                This Hotel shall not be responsible for any loss, destruction, deterioration or other damage to the article in custody which has occurred by reason of force majeure as the term is commonly defined.
                            </li>
                            <li>
                                Any person using the custody service shall have a duty to compensate for any damage incurred by this Hotel and/or any third party if such damage is due to the destruction or deterioration of the article in custody or any other reasons for which the said
                                person is responsible.
                            </li>
                        </ol>


                        <h4>
                            Article 7. Disposition of Article in Custody</h4>
                        <ol>
                            <li>
                                If the claimant does not claim the article in custody within 7 days after the expiration of the period of custody, all article(s) in custody shall be deemed abandoned and the Hotel shall dispose of the article(s) as it deems fit.
                            </li>
                            <li>
                                The cost of the disposition described in the paragraph immediately above shall be borne by the person who requested custody provided that any proceeds of said disposition shall be off-set against the costs of disposition.
                            </li>
                        </ol>

                        <h4>
                            Article 8. Emergency Steps</h4>
                        <p>
                            This Hotel may take emergency steps which are appropriate under the circumstances in the following events:
                        </p>
                        <ol>
                            <li>
                                Demand by legal authorities that the article in custody be opened or otherwise disposed of.
                            </li>
                            <li>
                                Fire, abnormality in the condition of the article or other exigent circumstances.
                        </ol>

                        <h4>
                            Article 9. Governing Language
                        </h4>
                        <p>
                            These Provisions are written both in Japanese and English. In the event of any inconsistency or difference between the two versions of these Provisions, the Japanese version shall prevail in all respects.
                        </p>


                        <h4>Article 10. Jurisdiction and Applicable Laws</h4>
                        <p>
                            Any dispute arising from or in relation to these Provisions shall be referred to the Japanese court having jurisdiction over the location of this Hotel and resolved in accordance with applicable Japanese Laws.
                        </p>

                    </div>
                </div>

            </div>
            <div class="bottom_menu">
                <p class="scroll_text"><span class="sankaku">◀︎</span>上へスクロール</p>
                <p class="scroll_text">下へスクロール<span class="sankaku">▶︎</span></p>
            </div>
        </div>

    </div>
</div>