<div class="main_content_wrapper">
  <div class="main_content">
    <ng-container *ngFor="let item of appList; let i = index">
      <div class="icon_item" [ngClass]="{ icon_focus: item.focused }" (click)="click(i)" (focus)="focus(i)" (blur)="blur()">
        <div class="icon_list">
          <div class="icon_area">
            <img class="icon_img" [src]="item.icon" />
          </div>
          <div class="icon_ttitle">{{ item.title }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
