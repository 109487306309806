<div class="wrapper">
    <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
    <div class="top_icon"></div>
    <div class="app_name">{{ appname }}</div>
    <div class="remote_control"></div>
    <div class="datetime">
        {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }} {{ now_date_obj.hhmm }}
    </div>
    <div class="main_content_wrapper">
        <div class="main_content">
            <!--コインランドリー-->
            <div *ngIf="hotelIndex == 0">
                <div class="ja" *ngIf="lang == 'jpn'">
                    <img src="/assets/hotel-info/laundromat.png" alt="laundromat">
                </div>

                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/hotel-info/laundromat_en.png" alt="laundromat">

                </div>
            </div>
            <!--自動販売機・製氷機-->
            <div *ngIf="hotelIndex == 1">
                <div class="ja flex_content" *ngIf="lang == 'jpn'">
                    <img src="/assets/hotel-info/vendingmachine.png" alt="vending machine/ice machine">

                </div>

                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/hotel-info/vendingmachine_en.png" alt="vending machine/ice machine">
                </div>

            </div>

            <!--ホテルショップ -->
            <div *ngIf="hotelIndex == 2">
                <div class="ja" *ngIf="lang == 'jpn'">

                    <img src="/assets/hotel-info/shop.png" alt="shop">
                </div>

                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/hotel-info/shop_en.png" alt="shop">
                </div>
            </div>

            <!--セルフクローク-->
            <div *ngIf="hotelIndex == 3">
                <div class="ja" *ngIf="lang == 'jpn'">

                    <img src="/assets/hotel-info/self-cloakroom.png" alt="self-cloakroom">
                </div>

                <div class="en" *ngIf="lang == 'eng'">
                    <img src="/assets/hotel-info/self-cloakroom_en.png" alt="self-cloakroom">
                </div>
            </div>
        </div>
    </div>
</div>