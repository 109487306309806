import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Weather } from './Weather';
@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  constructor(private http: HttpClient) {}
  url = 'https://dark-sky.antipasto.biz/hakata.json?id' + Math.random();
  proxy = 'https://cors-anywhere.herokuapp.com/';
  getWeather() {
    return this.http.get<Weather>(this.url);
  }
}
