import { Component, OnInit, OnDestroy } from '@angular/core';
import { RoomNoService } from '../service/room-no.service';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { KeyCode } from '../shared/const/KeyCode';
import { Router } from '@angular/router';
import { LanguageService } from '../service/language.service';
import {
  getNowDateObject,
  getStreamNowDate,
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-yakkan',
  templateUrl: './yakkan.component.html',
  styleUrls: ['./yakkan.component.scss'],
})
export class YakkanComponent implements OnInit, OnDestroy {
  scrollDown() {
    (document.querySelector('.keiyaku_wrapper_1') as HTMLDivElement).scrollBy(
      0,
      600
    );
  }
  scrollUp() {
    (document.querySelector('.keiyaku_wrapper_1') as HTMLDivElement).scrollBy(
      0,
      -600
    );
  }
  focusIndex = 0;
  index = 0;
  lang: 'jpn' | 'eng';
  appname: string;
  now_date = getStreamNowDate();
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  sub: Subscription[] = [];

  room_no = '';
  yakkan = require('./yakkan.json');
  kiyaku = require('./kiyaku.json');
  side_index = 0; // 本文、規約で何P目を表示するかのindex
  side_bar_index = 0; // サイドバーのフォーカスindex
  is_yakkan = true; // 約款か規約どちらを表示するか
  sidebar: SideBar<YakkanSideBarTiTleJpn, YakkanSideBarTitleEng>[] = [
    {
      title: '宿泊約款',
      title_jpn: '宿泊約款',
      title_eng: 'Regulation',
      checked: true,
      key_code: '1',
      focused: true,
    },
    {
      title: '利用規約',
      title_jpn: '利用規約',
      title_eng: 'Terms of service',
      checked: false,
      key_code: '2',
      focused: false,
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      checked: false,
      key_code: '10',
      focused: false,
    },
  ];
  yakkan_name: string;
  constructor(
    private router: Router,
    private roomNoService: RoomNoService,
    private languageService: LanguageService
  ) {}
  ngOnInit() {
    document.onkeydown = this.pushButton.bind(this);
    this.roomNoService.source = 'yakkan';

    // Languageをサービスしちゃう
    this.lang = this.languageService.language;
    if (this.languageService.language === 'eng') {
      this.appname = 'REGULATION';

      this.sidebar = this.sidebar.map(d => {
        return {
          title: d.title_eng,
          title_jpn: d.title_jpn,
          title_eng: d.title_eng,
          key_code: d.key_code,
          checked: d.checked,
          focused: d.focused,
        };
      });
    } else {
      this.appname = '宿泊約款';
    }

    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );

    // 約款と規約の日本語英語切り替え
    this.yakkan = this.yakkan.map((d: { path: string }) => {
      return {
        path: d.path.replace('jpn', this.languageService.language),
      };
    });

    this.kiyaku = this.kiyaku.map((d: { path: string }) => {
      return {
        path: d.path.replace('jpn', this.languageService.language),
      };
    });
  }
  ngOnDestroy() {
    document.onkeydown = null;
  }
  setFocusIndex(index: number) {
    if (index == 2) {
      this.router.navigate(['room', this.roomNoService.room_no]);
    } else {
      this.focusIndex = index;
      this.sidebar.forEach(d => (d.checked = false));
      this.sidebar[this.focusIndex].checked = true;
    }
  }

  pushButton(ev: KeyboardEvent) {
    // TODO チャンネルボタン押された時の処理
    const 種別 = this.side_bar_index === 0 ? 'yakkan' : 'kiyaku';
    ev.stopPropagation();
    if (ev.keyCode === KeyCode.VK_RIGHT) {
      // 横方向のインデックスインクリメント
      //console.log(this.side_index);
      // this.side_index = this.side_index + 1;
      this.scrollDown();
    } else if (ev.keyCode === KeyCode.VK_LEFT) {
      this.scrollUp();
    } else if (
      ev.keyCode === KeyCode.VK_DOWN &&
      this.side_bar_index !== this.sidebar.length - 1
    ) {
      // 縦方向のインデックスインクリメント
      this.side_bar_index = this.side_bar_index + 1;
      this.sidebar.forEach(d => (d.focused = false));
      this.sidebar[this.side_bar_index].focused = true;
      ev.preventDefault();
    } else if (ev.keyCode === KeyCode.VK_UP && this.side_bar_index !== 0) {
      // 縦方向のインデックスデクリメント
      this.side_bar_index = this.side_bar_index - 1;
      this.sidebar.forEach(d => (d.focused = false));
      this.sidebar[this.side_bar_index].focused = true;
      ev.preventDefault();
    } else if (ev.keyCode === KeyCode.VK_ENTER) {
      this.setFocusIndex(this.side_bar_index);
    }

    //console.log(ev.keyCode);
  }
}

declare function require(x: string): any;
type YakkanSideBarTiTleJpn = '宿泊約款' | '利用規約';
type YakkanSideBarTitleEng = 'Regulation' | 'Terms of service';
