<ul class="tab_menu">
  <ng-container *ngFor="let item of sidebars; let i = index">
    <!-- <a href="" (click)="click(i)"> -->
    <li
      (click)="click(i)"
      (focus)="focus(i)"
      (blur)="blur()"
      [ngClass]="{ active: item.checked, focused: item.focused }"
      class="tab_menu_li active "
      tabindex="0"
    >
      {{ item.title }}
    </li>
    <!-- </a> -->
  </ng-container>
</ul>
