import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideBar } from '../shared/component/sidebar/siderbar';
import { RoomNoService } from '../service/room-no.service';
import { Router } from '@angular/router';
import { BraviaApiService } from '../service/bravia-api.service';
import { Observable, Subscription } from 'rxjs';
import {
  getNowDateObject,
  getStreamNowDate,
} from '../shared/tools/DateNowTime';
import { nowDate } from '../index/nowDate';
import { LanguageService } from '../service/language.service';

@Component({
  selector: 'app-nearby-info',
  templateUrl: './nearby-info.component.html',
  styleUrls: ['./nearby-info.component.scss'],
})
export class NearbyInfoComponent implements OnInit, OnDestroy {
  now_date = getStreamNowDate();
  sub: Subscription[] = [];
  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  lang = '';
  focusIndex = 0;
  rentalIndex = 0;
  sidebar: SideBar<NearbylInfoSideBarTiTleJpn, NearbylInfoSideBarTiTleEng>[] = [
    {
      title: 'ドーム',
      title_jpn: 'ドーム',
      title_eng: 'Dome',
      key_code: '1',
      checked: true,
      focused: true,
    },
    {
      title: '公園',
      title_jpn: '公園',
      title_eng: 'Park',
      key_code: '2',
      checked: false,
      focused: false,
    },
    {
      title: 'お出かけスポット',
      title_jpn: 'お出かけスポット',
      title_eng: 'Tourist spot',
      key_code: '3',
      checked: false,
      focused: false,
    },
    {
      title: 'ショッピング',
      title_jpn: 'ショッピング',
      title_eng: 'Shopping',
      key_code: '4',
      checked: false,
      focused: false,
    },
    {
      title: 'グルメ',
      title_jpn: 'グルメ',
      title_eng: 'Gourmet',
      key_code: '5',
      checked: false,
      focused: false,
    },
    {
      title: 'メニューへ戻る',
      title_jpn: 'メニューへ戻る',
      title_eng: 'Back to menu',
      key_code: '92',
      checked: false,
      focused: false,
    },
  ];

  appname: string;

  constructor(
    private roomNoService: RoomNoService,
    private router: Router,
    private braviaService: BraviaApiService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.lang = this.languageService.language;

    if (this.languageService.language === 'jpn') {
      this.appname = '周辺情報';
      return;
    } else {
      this.appname = 'Nearby Information';
    }
    this.sidebar = this.sidebar.map(d => {
      d.title = d.title_eng;
      return d;
    });

    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );
  }
  ngOnDestroy() {
    this.sub.forEach(d => d.unsubscribe());
  }
  setFocusIndex(index: number) {
    if (
      ['メニューへ戻る', 'Back to menu'].includes(this.sidebar[index].title)
    ) {
      this.router.navigate(['/room', this.roomNoService.room_no]);
    }
    this.focusIndex = index;
    this.sidebar.forEach(d => (d.checked = false));
    this.sidebar[this.focusIndex].checked = true;
    this.rentalIndex = index;
  }
}
type NearbylInfoSideBarTiTleJpn =
  | 'ドーム'
  | '公園'
  | 'お出かけスポット'
  | 'ショッピング'
  | 'グルメ'
  | 'メニューへ戻る';
type NearbylInfoSideBarTiTleEng =
  | 'Dome'
  | 'Park'
  | 'Tourist spot'
  | 'Shopping'
  | 'Gourmet'
  | 'Back to menu';
