<div class="wrapper">
  <app-appli-side-bar [sidebars]="sidebar" (clickEventIndex)="setFocusIndex($event)"></app-appli-side-bar>
  <div class="top_icon"></div>
  <div class="app_name">{{ appname }}</div>
  <div class="remote_control"></div> 
  <div class="datetime">   {{ now_date_obj.year }}.{{ now_date_obj.month }}.{{ now_date_obj.day }}
      {{ now_date_obj.hhmm }}</div>

  <app-appli-main
    [appList]="appList[appListIndex]"
    (changeFocus)="appliMainChangeFocus($event)"
    (launchAppli)="launchAppli($event)"
    (resetAllFocus)="resetAllFocus()"
  ></app-appli-main>
</div>
